import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import About from "../components/About";
import BreadCrumbs from "../components/BreadCrumbs";

export default function AboutUs() {
  return (
    <>
      <Header />
      <BreadCrumbs pageTitle={"About Us"} navLink={"/"} />
      <About />
      <Footer />
    </>
  );
}
