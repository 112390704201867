import React from "react";
import ReviewForm from "../components/ReviewForm";

export default function TestimonialForm() {
  return (
    <>
      <ReviewForm />
    </>
  );
}
