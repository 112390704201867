import React from "react";
import Header from "../components/Header";
import FreebiesList from "../components/FreebiesList";
import Footer from "../components/Footer";
import BreadCrumbs from "../components/BreadCrumbs";
import { useSearchParams } from "react-router-dom";
import validFreebies from "../data/validFreebies.json";

export default function Freebies() {
  const [searchParams] = useSearchParams();
  const freebieType = searchParams.get("freebie");

  const isValidFreebie = (type) => {
    return validFreebies.validTypes.includes(type);
  };

  const isValid = freebieType && isValidFreebie(freebieType);

  return (
    <>
      <Header />
      {/* <BreadCrumbs pageTitle={"Freebies"} navLink={"/"} /> */}
      <FreebiesList isValid={isValid} freebieType={freebieType} />
      <Footer />
    </>
  );
}
