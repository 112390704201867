import React from "react";

export default function Services({ className, limit }) {
  const services = [
    {
      icon: "icon-web",
      title: "Web Design and Development",
      description:
        "Crafting custom, responsive websites that are visually appealing, user-friendly, and optimized for performance.",
    },
    {
      icon: "icon-rating-stars-1",
      title: "Branding and Logo Design",
      description:
        "Building unique brand identities and memorable logos that effectively communicate your business ethos and values.",
    },
    {
      icon: "icon-design-tools",
      title: "UI/UX Design",
      description:
        "Designing intuitive and engaging user experiences and interfaces, focusing on usability and visual impact.",
    },
    {
      icon: "icon-branding",
      title: "Graphic Design",
      description:
        "Creating compelling visual content, from brochures to digital assets, that tells your brand's story in a captivating way.",
    },
    // Additional services
    {
      icon: "icon-layers",
      title: "Packaging Design",
      description:
        "Developing creative and functional packaging designs that protect products and appeal to consumers.",
    },
    {
      icon: "icon-chef",
      title: "Illustration",
      description:
        "Crafting custom illustrations that add a unique visual element to your storytelling across various media.",
    },
    {
      icon: "icon-target-1",
      title: "Product Design",
      description:
        "Innovating product design solutions with a focus on ergonomics, aesthetics, and practical functionality.",
    },
    {
      customIcon: "ecommerce.png",
      title: "E-commerce Development",
      description:
        "Creating robust, scalable e-commerce platforms tailored to drive sales and improve the online shopping experience.",
    },
    {
      icon: "icon-design-thinking",
      title: "Mobile App Development",
      description:
        "Developing high-performance, feature-rich mobile applications for iOS and Android platforms, enhancing user engagement.",
    },
    {
      icon: "icon-seo",
      title: "SEO",
      description:
        "Optimizing websites to improve search engine rankings and visibility, driving organic traffic and enhancing online presence.",
    },
    {
      icon: "fa fa-file-video",
      title: "Video Production",
      description:
        "Producing high-quality, engaging video content for marketing, corporate communications, and digital platforms.",
    },
  ];

  const consolidatedServices = [
    {
      icon: "icon-web",
      title: "Web Design and Development",
      description:
        "Offering a full spectrum of web solutions, including responsive web design, UI/UX development, e-commerce platform creation, and mobile app development. Our focus is on crafting user-friendly, visually appealing, and performance-optimized websites and applications across various platforms.",
    },
    {
      icon: "icon-branding",
      title: "Brand & Graphic Design",
      description:
        "Developing distinctive brand identities and logos, creating engaging visual content such as brochures and digital assets, crafting unique illustrations, and designing appealing packaging. Our services are focused on delivering cohesive and impactful graphic design solutions that resonate with your audience.",
    },
    {
      icon: "icon-seo",
      title: "Digital Marketing, SEO & Video Production",
      description:
        "Optimizing websites to enhance search engine rankings and visibility, driving organic traffic, and producing high-quality, engaging video content for marketing and digital platforms. Our integrated approach combines SEO expertise with creative video production to elevate your online presence and marketing efforts.",
    },
  ];

  const displayedServices = limit ? services.slice(0, limit) : services;

  return (
    <div id="our-services" className={`section aximo-section-padding3 ${className}`}>
      <div className="container">
        <div className="aximo-section-title center">
          <h2>
            Inside our Creative Lab
            <span className="aximo-title-animation">
              <span className="aximo-title-icon">
                <img src="assets/images/v1/star2.png" alt="Interactive geometric shape" />
              </span>
            </span>
          </h2>
        </div>
        <div className="row justify-content-center">
          {consolidatedServices.map((service, index) => (
            <div className="col-lg-6" key={index}>
              <div
                className={`aximo-iconbox-wrap wow fadeInUpX`}
                data-wow-delay={`${index * 0.1}s`}
              >
                <div className="aximo-iconbox-icon">
                  {service.customIcon ? (
                    <img
                      style={{ maxWidth: "80px" }}
                      src={`assets/images/services/${service.customIcon}`}
                      alt={service.title + " " + "service icon"} 
                    />
                  ) : (
                    <i className={service.icon}></i>
                  )}
                </div>
                <div className="aximo-iconbox-data">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
