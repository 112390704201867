import React from "react";

export default function CTA() {
  return (
    <>
      <div className="aximo-cta2-section aximo-section-padding extra-side-margin dark-bg3 mb-5">
        <div className="container">
          <div className="aximo-cta2-wrap">
            <h2>Have a project in mind? Let's work together!</h2>
            <p>
              We offer a spectrum of creative solutions designed to enhance
              businesses' online presence and client growth. Quality is our
              promise, ensuring top-notch results in every project.
            </p>
            <a
              style={{ width: "200px" }}
              className="aximo-default-btn pill aximo-header-btn"
              href="https://calendly.com/weblabs-studio/schedule"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get In Touch
            </a>
          </div>
        </div>
        <div className="aximo-cta-shape3">
          <img src="assets/images/v5/shape4.png" alt="Interactive geometric shape" />
        </div>
      </div>
    </>
  );
}
