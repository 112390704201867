import React, { useEffect, useState } from "react";
import freebiesData from "../data/freebies.json";

export default function FreebiesList({ isValid, freebieType }) {
  let freebieDetails;

  if (isValid) {
    freebieDetails = freebiesData.find(
      (freebie) => freebie.title === freebieType
    );
  }

  const downloadFile = (url, filename) => {
    if (!url) return;

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  return (
    <>
      {!isValid ? (
        <>
          <div className="section aximo-section-padding">
            <div className="container">
              <div className="aximo-section-title center">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="text-capitalize">
                      <span className="aximo-title-animation">Invalid URL</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="section aximo-section-padding mt-5">
          <div className="container">
            <div className="aximo-section-title center">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="text-capitalize">
                    <span className="aximo-title-animation">
                      {freebieType} Freebies
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            <div>
              {freebieDetails?.freebies.map((ele, index) => (
                <div onClick={() => downloadFile(ele.downloadUrl, ele.title)} key={index} className="freebie-link-container">
                  <div className="freebie-link-icon-container">
                    <img
                      className="freebie-link-icon"
                      src={`assets/images/freebies/${freebieDetails?.icon}`}
                      alt="freebie-icon"
                    />
                  </div>

                  <div>{ele.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
