import React, { useState } from "react";
import firebaseDb from "../services/firebaseService";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfig from "../services/toastConfig";
import { Oval } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';

export default function ReviewForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    company: "",
    message: "",
    rating: 0,
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: inputValue });
  };

  const setRating = (rating) => {
    setFormData((prevState) => ({
      ...prevState,
      rating: rating,
    }));
  };

  const resetForm = () => {
    setFormData({
      name: "",
      designation: "",
      company: "",
      message: "",
      rating: 0,
      consent: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    addDoc(collection(firebaseDb, "testimonials"), {
      name: formData.name,
      designation: formData.designation,
      company: formData.company,
      message: formData.message,
      rating: formData.rating,
      consent: formData.consent,
      isVisible: false,
    })
      .then(() => {
        toast.success("Testimonial Submitted", toastConfig);
        resetForm();
        setLoading(false);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        toast.error("An Error Occurred.", toastConfig);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="aximo-logo-section">
        <div className="container">
          <a href="index.html">
            <img
              style={{ width: "128px" }}
              src="assets/images/logo/logo-white.png"
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="section aximo-section-padding">
        <div className="container">
          <div className="aximo-account-title">
            <h2>
              <span className="aximo-title-animation">
                Your Feedback Matters!
              </span>
            </h2>
          </div>
          <div
            className="aximo-account-wrap wow fadeInUpX"
            data-wow-delay="0.1s"
          >
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="aximo-main-field">
                    <label>Your name</label>
                    <input
                      placeholder="e.g. John Doe"
                      required
                      disabled={loading}
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="aximo-main-field">
                    <label>Designation</label>
                    <input
                      placeholder="e.g. Founder ABC Organization"
                      required
                      disabled={loading}
                      type="text"
                      name="designation"
                      value={formData.designation}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="aximo-main-field">
                    <label>Company / Organization</label>
                    <input
                      placeholder="e.g. ABC Organization"
                      required
                      disabled={loading}
                      type="text"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="aximo-main-field">
                    <label>Star Rating</label>
                    <div className="aximo-star-rating">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover || formData.rating) ? "on" : "off"
                            }
                            onClick={() => setRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(formData.rating)}
                            disabled={loading}
                          >
                            <span className="star">&#9733;</span>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="aximo-main-field">
                <label>Write your feedback here...</label>
                <textarea
                  required
                  disabled={loading}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="aximo-account-checkbox">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                />
                <label htmlFor="consent">
                  I consent to use my social media image or company logo
                  alongside my testimonial.
                </label>
              </div>
              <button id="aximo-account-btn" type="submit">
                {loading ? (
                  <Oval
                    visible={true}
                    height="40"
                    width="40"
                    color="#DEE11D"
                    ariaLabel="oval-loading"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
