import React from "react";

export default function QuickIntro() {
  return (
    <div className="section aximo-section-padding">
      <div className="container">
        <div className="aximo-section-title">
          <div data-wow-delay="0.1s" className="row wow fadeInUpX">
            <div className="col-lg-7">
              <h2 className="aximo-quick-intro-title">
                <span className="aximo-title-animation">
                  Why Sky's never the limit ?
                  {/* <span className="aximo-title-icon">
                    <img src="assets/images/v1/star2.png" alt="" />
                  </span> */}
                </span>
              </h2>
            </div>
            <div className="col-lg-4 offset-lg-1 d-flex align-items-center">
              <p>
                Our philosophy is simple: there are no barriers to our goals and
                visions, as we aim for infinite heights in all we do.
              </p>
            </div>
          </div>
        </div>

        <div
          data-wow-delay="0.1s"
          className="aximo-story-content wow fadeInUpX"
        >
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <div style={{ maxWidth: "500px" }}>
                <img src="assets/images/logo/subMark-detail.png" alt="Weblabs Studio Detail Logo - Creative Agency" />
              </div>
            </div>
            {/* <div className="col-lg-6">
              <h3>Core Elements of Our Identity</h3>
              <h5 className="aximo-quick-intro-sub-heading">
                Creativity: Imagination in Action
              </h5>
              <p>
                We breathe life into ideas, transforming concepts into dynamic
                designs that stand apart.
              </p>

              <h5 className="aximo-quick-intro-sub-heading">
                Innovation: Designing Tomorrow
              </h5>
              <p>
                Pioneering solutions that anticipate trends, we're committed to
                pushing the boundaries of possibility.
              </p>

              <h5 className="aximo-quick-intro-sub-heading">
                Growth: Elevating Potential
              </h5>
              <p>
                Fostering progress, our strategies are crafted to scale your
                brand to new heights.
              </p>

              <a className="aximo-default-btn aximo-default-btn-outline pill outline-black" href="#">
                  <span className="aximo-label-up">Learn More</span>
                  <span className="aximo-label-up">Learn More</span>
                </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
