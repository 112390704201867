export const faqs = [
  {
    question: "What Services Does Weblabs Studio Offer?",
    answer:
      "Weblabs Studio offers a diverse range of creative services, including web design and development, brand and graphic design, and digital marketing with a focus on SEO and video production. We provide comprehensive solutions to enhance your online presence and brand identity.",
  },
  // {
  //   question: "What Makes Weblabs Studio Unique Compared to Other Agencies?",
  //   answer:
  //     "Weblabs Studio offers a diverse range of creative services, including web design and development, brand and graphic design, and digital marketing with a focus on SEO and video production. We provide comprehensive solutions to enhance your online presence and brand identity.",
  // },
  {
    question:
      "Why wouldn't I just hire a freelancer instead of Weblabs Studio?",
    answer:
      "While freelancers can be talented, hiring Weblabs Studio offers several advantages. We provide a team of experts with diverse skills, ensuring a well-rounded approach to your project. We offer project management, quality assurance, and scalability that may be challenging to achieve with a single freelancer.",
  },
  {
    question:
      "Do I need to be in business to collaborate with Weblabs Studio?",
    answer:
      "We welcome collaborations with both businesses and individuals. Whether you're a startup, an established company, or an individual looking for creative solutions, Weblabs Studio is here to assist you.",
  },
  {
    question:
      "Can Weblabs Studio redesign an existing website or branding materials?",
    answer:
      "Yes, we can certainly revamp and redesign existing websites, logos, and branding materials to align them with your current goals and objectives. We're committed to enhancing your digital presence.",
  },
  {
    question:
      "What is the typical timeline for completing a project with Weblabs Studio?",
    answer:
      "Project timelines can vary based on the scope and complexity. We work closely with our clients to establish realistic timelines and ensure timely project delivery.",
  },
  {
    question:
      "How do I request a quote or estimate for a project with Weblabs Studio?",
    answer:
      "Requesting a quote is easy. Simply reach out to us through our contact form or email, providing project details, and we'll promptly provide you with a customized quote.",
  },
  {
    question:
      "Does Weblabs Studio offer support after project completion?",
    answer:
      "Yes, we offer post-project maintenance and support to ensure your website and creative assets continue to perform optimally and stay up-to-date.",
  },
];
