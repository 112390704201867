import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToElement = (id) => {
    if (window.innerWidth <= 992) {
      const menuOverlay = document.getElementById("menu-overlay");
      const menuBlock = document.getElementById("append-menu-header");

      if (menuBlock && menuBlock.classList.contains("active")) {
        menuBlock.classList.remove("active");
        menuOverlay.classList.remove("active");
      }
    }

    if (location.pathname !== "/") {
      navigate("/", { replace: true });
    }

    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <>
      <header
        className="site-header site-header--menu-center aximo-header-section aximo-header1 dark-bg3"
        id="sticky-menu"
      >
        <div className="container">
          <nav className="navbar site-navbar">
            {/* <!-- Brand Logo--> */}
            <div className="brand-logo">
              <a href="/">
                <img
                  src="assets/images/logo/logo-white.png"
                  alt="Weblabs Studio Logo - Creative Agency"
                  className="light-version-logo weblabs-light-logo"
                />
              </a>
            </div>
            <div className="menu-block-wrapper">
              <div className="menu-overlay" id="menu-overlay"></div>
              <nav className="menu-block" id="append-menu-header">
                <div className="mobile-menu-head">
                  <div className="go-back">
                    <i className="fa fa-angle-left"></i>
                  </div>
                  <div className="current-menu-title"></div>
                  <div className="mobile-menu-close">&times;</div>
                </div>
                <ul className="site-menu-main">
                  <li className="nav-item">
                    <button
                      onClick={() => scrollToElement("home")}
                      href="#"
                      className="nav-link-item"
                    >
                      Home
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={() => scrollToElement("our-services")}
                      href="#"
                      className="nav-link-item"
                    >
                      Services
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={() => scrollToElement("why-us")}
                      href="#"
                      className="nav-link-item"
                    >
                      Why Us
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={() => scrollToElement("faqs")}
                      href="#"
                      className="nav-link-item"
                    >
                      FAQs
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={() => scrollToElement("contact-us")}
                      href="#"
                      className="nav-link-item"
                    >
                      Contact Us
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
              <a
                className="aximo-default-btn pill aximo-header-btn"
                href="https://calendly.com/weblabs-studio/schedule"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Started
              </a>
            </div>
            {/* <!-- mobile menu trigger --> */}
            <div className="mobile-menu-trigger light">
              <span></span>
            </div>
            {/* <!--/.Mobile Menu Hamburger Ends--> */}
          </nav>
        </div>
      </header>
    </>
  );
}
