import React from "react";

export default function Hero() {
  return (
    <div id="home" className="aximo-hero-section2 dark-bg3">
      <div className="container position-relative">
        <div className="aximo-hero-content2">
          <h1>Sky's never the limit</h1>
          <p>
            The only creative agency you need, offering full spectrum of
            Creative Services
          </p>
          <div className="aximo-hero-btn-wrap gap-4">
            <a
              className="aximo-call-btn mt-0 wow fadeInUpX"
              data-wow-delay="0.1s"
              href="https://calendly.com/weblabs-studio/schedule"
              target="_blank"
              rel="noopener noreferrer"
            >
              Interested? Let's Talk & Plan <i className="icon-call"></i>
            </a>
          </div>
        </div>
        <div className="aximo-hero-shape1">
          <img src="assets/images/v2/shape1.png" alt="Interactive geometric shape" />
        </div>
        <div className="aximo-hero-shape2">
          <img src="assets/images/v2/shape-star.png" alt="Interactive geometric shape" />
        </div>
      </div>
    </div>
  );
}
