import React from "react";

export default function About() {
  return (
    <>
      <div className="section aximo-section-padding">
        <div id="aximo-counter"></div>
        <div className="container">
          <div className="aximo-section-title">
            <div className="row">
              <div className="col-lg-7">
                <h2>
                  <span className="aximo-title-animation">
                    We make your&nbsp;
                    <span className="aximo-title-icon">
                      <img src="assets/images/v1/star2.png" alt="" />
                    </span>
                  </span>
                  business stand out
                </h2>
              </div>
              <div className="col-lg-4 offset-lg-1 d-flex align-items-center">
                <p>
                  We work closely with our clients to know their objectives,
                  target audience, unique needs, and practical design solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="aximo-video-wrap wow fadeInUpX" data-wow-delay="0.1s">
                <img src="assets/images/v1/video-bg.png" alt="" />
                <a
                  className="aximo-video-popup video-init"
                  href="https://www.youtube.com/watch?v=Vx2aLNgGoAE"
                >
                  <img src="assets/images/v1/play-btn.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="aximo-counter-wrap">
                <div className="aximo-counter-data">
                  <h2 className="aximo-counter-number">
                    <span data-percentage="15" className="aximo-counter"></span>+
                  </h2>
                  <p>Years of experience</p>
                </div>
                <div className="aximo-counter-data">
                  <h2 className="aximo-counter-number">
                    <span data-percentage="120" className="aximo-counter"></span>k
                  </h2>
                  <p>Successful projects</p>
                </div>
                <div className="aximo-counter-data">
                  <h2 className="aximo-counter-number">
                    <span data-percentage="100" className="aximo-counter"></span>%
                  </h2>
                  <p>Client satisfaction rate</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section aximo-section-padding6">
        <div className="container">
          <div className="aximo-section-title center title-description">
            <h2>
              <span className="aximo-title-animation">
                We think our story is&nbsp;
                <span className="aximo-title-icon">
                  <img src="assets/images/v1/star2.png" alt="" />
                </span>
              </span>
              worth sharing with you
            </h2>
            <p>
              Established in 2008, we began as a small but ambitious team. We
              understood the importance of creative and tech-savvy solutions to
              help businesses succeed in the ever-changing digital landscape.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <div
                className="aximo-story-thumb wow fadeInUpX"
                data-wow-delay="0.1s"
              >
                <img src="assets/images/about/story1.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="aximo-story-thumb wow fadeInUpX"
                data-wow-delay="0.2s"
              >
                <img src="assets/images/about/story2.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="aximo-story-thumb wow fadeInUpX"
                data-wow-delay="0.3s"
              >
                <img src="assets/images/about/story3.png" alt="" />
              </div>
            </div>
            <div className="col-lg-8">
              <div
                className="aximo-story-thumb wow fadeInUpX"
                data-wow-delay="0.4s"
              >
                <img src="assets/images/about/story4.png" alt="" />
              </div>
            </div>
          </div>

          <div className="aximo-story-content">
            <div className="row">
              <div className="col-lg-6">
                <h3>Our core vision</h3>
                <p>
                  Empowering businesses to create impactful and visually
                  stunning brand experiences that captivate audiences and drive
                  success in the digital age.{" "}
                </p>
                <p>
                  Our team consists of experienced designers, developers. We
                  have a wide variety of skills and backgrounds, allowing us to
                  tackle projects of all sizes and complexities. We believe in
                  the power of imagination and innovation.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Our main mission</h3>
                <p>
                  Our mission is to collaborate with businesses of all sizes,
                  from startups to established brands, to provide innovative and
                  creative design solutions.{" "}
                </p>
                <p>
                  We are committed to creating designs that inspire, connect &
                  our clients in the marketplace. Our focus is on understanding.
                  Our clients' unique needs and delivery designs that not only
                  meet but exceed their expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="aximo-auto-slider-section">
        <div className="swiper aximo-auto-slider">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Let's create new experiences</h3>
                <img src="assets/images/v1/star3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
