import React from "react";

export default function HowItWorks() {
  return (
    <div id="why-us" className="section bg-light2 aximo-section-padding3 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="aximo-section-title clash-grotesk">
              <h2>Why us?</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div
              className="aximo-iconbox-wrap2 wow fadeInUpX"
              data-wow-delay="0.1s"
            >
              <div className="aximo-iconbox-icon2">
                <img src="assets/images/v2/illustrator1.png" alt="Why Us - Creative Solutions Illustration" />
              </div>
              <div className="aximo-iconbox-data2">
                <h3>Total Creative Solutions</h3>
                <p>
                  We Offer a spectrum of creative solutions, from branding to
                  web design.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div
              className="aximo-iconbox-wrap2 wow fadeInUpX"
              data-wow-delay="0.2s"
            >
              <div className="aximo-iconbox-data2">
                <h3>Distinct from Crowd</h3>
                <p>
                  At Weblabs Studio, being different isn't just an option, it's
                  our foundation.
                </p>
              </div>
              <div className="aximo-iconbox-icon2 bottom">
                <img src="assets/images/v2/illustrator2.png" alt="Why Us - Distinct from Crowd Illustration" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div
              className="aximo-iconbox-wrap2 wow fadeInUpX"
              data-wow-delay="0.3s"
            >
              <div className="aximo-iconbox-icon2">
                <img src="assets/images/v2/illustrator3.png" alt="Why Us -Client-Driven Process Illustration" />
              </div>
              <div className="aximo-iconbox-data2">
                <h3>Client-Driven Process</h3>
                <p>
                  Our key to success is collaborative client involvement
                  throughout the process
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aximo-shape">
        <img src="assets/images/v2/shape2.png" alt="" />
      </div>
    </div>
  );
}
