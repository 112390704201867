import React, { useState } from "react";
import firebaseDb from "../services/firebaseService";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfig from "../services/toastConfig";
import { Oval } from "react-loader-spinner";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    addDoc(collection(firebaseDb, "contactFormSubmissions"), {
      name: formData.name,
      email: formData.email,
      message: formData.message,
      formLocation: "footer",
    })
      .then(() => {
        toast.success("Message Sended Successfully.", toastConfig);
        resetForm();
        setLoading(false);
      })
      .catch((error) => {
        toast.error("An Error Occurred.", toastConfig);
        setLoading(false);
      });
  };

  return (
    <>
      <footer id="contact-us" className="aximo-footer-section dark-bg3">
        <div className="container">
          <div className="aximo-footer-top aximo-section-padding">
            <div className="row">
              <div className="col-lg-7">
                <div className="aximo-default-content light position-relative">
                  <h2>
                    <span className="aximo-title-animation">
                      Let's start a&nbsp;
                    </span>
                    project together
                  </h2>
                  <p>
                    We work closely with our clients to understand their
                    objectives, target audience, and unique needs. We use our
                    creative skills to translate these requirements and
                    practical design solutions.
                  </p>
                  <div className="aximo-info-wrap">
                    <div className="aximo-info">
                      <ul>
                        <li>Send us an email:</li>
                        <li>
                          <a href="mailto:weblabs.studio@gmail.com">
                            weblabs.studio@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="aximo-social-icon social-large">
                    <ul>
                      <li>
                        <a
                          href="https://twitter.com/weblabs_studio"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <i className="icon-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/Creative.WeblabsStudio"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <i className="icon-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/weblabs.studio"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <i className="icon-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/weblabs-studio"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <i className="icon-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="aximo-hero-shape aximo-footer-shape">
                    <img
                      src="assets/images/v1/shape1.png"
                      alt="Interactive geometric shape"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="aximo-form-wrap">
                  <h4>Send us a message</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="aximo-form-field">
                      <input
                        required
                        disabled={loading}
                        type="text"
                        placeholder="Your name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="aximo-form-field">
                      <input
                        required
                        disabled={loading}
                        type="email"
                        placeholder="Your email address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="aximo-form-field">
                      <textarea
                        required
                        disabled={loading}
                        name="message"
                        placeholder="Write your message here..."
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <button id="aximo-submit-btn" type="submit">
                      {loading ? (
                        <Oval
                          visible={true}
                          height="40"
                          width="40"
                          color="#DEE11D"
                          ariaLabel="oval-loading"
                        />
                      ) : (
                        <>
                          Send message
                          <span>
                            <img
                              src="assets/images/icon/arrow-right3.svg"
                              alt="Interactive geometric shape"
                            />
                          </span>
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="aximo-footer-bottom">
            <div className="row">
              <div className="col-lg-6">
                <div className="aximo-copywright one text-start">
                  <p>
                    A Unit of{" "}
                    <a
                      href="https://brightenterprises.co"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#DEE11D" }}
                    >
                      Bright Enterprises
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="aximo-copywright one">
                  <p> &copy; Copyright {currentYear}, All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </>
  );
}
