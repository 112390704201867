import React from "react";

export default function BreadCrumbs({pageTitle, navLink}) {
  return (
    <>
      <div className="aximo-breadcrumb">
        <div className="container">
          <h1 className="post__title">{pageTitle}</h1>
          <nav className="breadcrumbs">
            <ul>
              <li>
                <a href={navLink}>Home</a>
              </li>
              <li aria-current="page"> {pageTitle}</li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
