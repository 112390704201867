import React from "react";

export default function NotFound() {
  return (
    <div className="section">
      <div className="container">
        <div className="aximo-errors-wrap p-0">
          <div className="aximo-errors-thumb">
            <img src="assets/images/about/404.png" alt="" />
          </div>
          <div className="aximo-errors-title">
            <h2>
              We can’t find the page
              <span className="aximo-title-animation">
                you are looking for
                <span className="aximo-title-icon">
                  <img src="assets/images/v1/star2.png" alt="" />
                </span>
              </span>
            </h2>
          </div>
          <a
            className="aximo-errors-btn wow fadeInUpX"
            data-wow-delay="0.1s"
            href="/"
          >
            Return to homepage
            <span>
              <img src="assets/images/icon/arrow-right.svg" alt="" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
