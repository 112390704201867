import React, { useState } from "react";
import { faqs } from "../services/faq";

export default function Faq() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = (index) => {
    // If the clicked item is already open, close it. Otherwise, open the clicked item.
    const isItemOpen = openIndex === index;
    setOpenIndex(isItemOpen ? null : index);
  };

  return (
    <>
      <div id="faqs" className="section aximo-section-padding3">
        <div className="container">
          <div className="aximo-section-title center">
            <h2>
              <span className="aximo-title-animation">
                These FAQs help clients learn about us
              </span>
            </h2>
          </div>
          <div
            className="aximo-accordion-wrap wow fadeInUpX"
            data-wow-delay="0.1s"
            id="aximo-accordion"
          >
            {faqs.map((faq, index) => (
              <div
                className={`aximo-accordion-item ${
                  openIndex === index ? "open" : ""
                }`}
                key={index}
              >
                <div
                  className="aximo-accordion-header"
                  onClick={() => toggleItem(index)}
                >
                  <h3>{faq.question}</h3>
                </div>
                <div className="aximo-accordion-body">
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
