import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import OurServices from "./pages/our-services";
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";
import Freebies from "./pages/freebies";
import Error from "./pages/error";
import Loader from "./components/Loader";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import TestimonialForm from "./pages/testimonial-form";
import FloatingCta from "./components/FloatingCta";
import WOW from "wowjs";

function RouteChangeTracker({ children }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const wow = new WOW.WOW({
      mobile: false, 
      tablet: false,
    });
    wow.init();
  }, []);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      {children}
    </>
  );
}

function App() {
  useEffect(() => {
    const scriptUrls = [
      "assets/js/bootstrap.bundle.min.js",
      "assets/js/aos.js",
      "assets/js/menu/menu.js",
      "assets/js/gsap.min.js",
      "assets/js/isotope.pkgd.min.js",
      "assets/js/jquery.magnific-popup.min.js",
      // "assets/js/swiper-bundle.min.js",
      "assets/js/countdown.js",
      // "assets/js/wow.min.js",
      "assets/js/SplitText.min.js",
      // "assets/js/ScrollTrigger.min.js",
      // "assets/js/ScrollSmoother.min.js",
      "assets/js/skill-bar.js",
      // "assets/js/faq.js",
      "assets/js/app.js",
    ];

    scriptUrls.forEach((url) => {
      // Check if the script already exists in the document
      if (!document.querySelector(`script[src="${url}"]`)) {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
      }
    });

    return () => {
      scriptUrls.forEach((url) => {
        let existingScript = document.querySelector(`script[src="${url}"]`);
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      });
    };
  }, []);

  return (
    <Router>
      <Analytics />
      <SpeedInsights />
      <RouteChangeTracker>
      <SpeedInsights />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          {/* <Route path="about-us" element={<AboutUs />} />
          <Route path="our-services" element={<OurServices />} />
          <Route path="contact-us" element={<ContactUs />} /> */}
          <Route path="freebies" element={<Freebies />} />
          <Route path="share-your-views" element={<TestimonialForm />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </RouteChangeTracker>
      <FloatingCta />
    </Router>
  );
}

export default App;
