import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Services from "../components/Services";
import HowItWorks from "../components/HowItWorks";
import CaseStudy from "../components/CaseStudy";
import Hero from "../components/Hero";
import QuickIntro from "../components/QuickIntro";
import Clients from "../components/Clients";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import CTA from "../components/CTA";
import VideoTestimonials from "../components/VideoTestimonials";

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      <Services limit={4} />
      {/* <Clients /> */}
      <HowItWorks />
      <QuickIntro />
      {/* <VideoTestimonials /> */}
      {/* <CaseStudy /> */}
      {/* <Testimonials /> */}
      <Faq />
      <CTA />
      <Footer />
    </>
  );
}
