import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCjchWcTubg0aRsZnWVvLhdjrZUt4z2RU4",
    authDomain: "weblabs-studio.firebaseapp.com",
    projectId: "weblabs-studio",
    storageBucket: "weblabs-studio.appspot.com",
    messagingSenderId: "815786212814",
    appId: "1:815786212814:web:77e5f9c756e12b8777bf9f"
  };
  
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseDb = getFirestore(firebaseApp);

  export default firebaseDb;