import React from "react";

export default function FloatingCta() {
  return (
    <div className="floating-cta-container">
      <a
        className="aximo-default-btn pill aximo-header-btn"
        href="https://calendly.com/weblabs-studio/schedule"
        target="_blank"
        rel="noopener noreferrer"
      >
        Get Started
      </a>
    </div>
  );
}
